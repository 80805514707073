import React from "react"
import { Alert, Toggle } from "../../lib"
import { EMAIL } from "../../../config"

export default function FailedMsg({ on, toggle }) {
	return (
		<Toggle>
			{() => (
				<Alert on={on} toggle={toggle}>
					<p>
						<span aria-label="face screaming in fear emoji" role="img">
							😱
						</span>{" "}
						Oops! The form didn't send.
					</p>
					<p css="font-size: 16px; font-weight: bold;">
						Try us here{" "}
						<span aria-label="Backhand Index Pointing Right emoji" role="img">
							👉
						</span>{" "}
						{EMAIL}
					</p>
				</Alert>
			)}
		</Toggle>
	)
}
