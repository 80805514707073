import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { fadedSlate, darkSlate, elevation, Container, Button, horizontalWhiteGreyAfterBG } from "../../styles/index.js"
import SuccessModal from "./SuccessModal.js"
import FailedMsg from "./FailedMsg.js"
import env from "../../publicEnv.js"

export default function ContactForm() {
	const initialState = {
		senders_name: "",
		senders_email: "",
		senders_phone: "",
		message_html: "",
		user_email: "",
	}

	const [formState, setFormState] = useState(initialState)

	const initialSubmitStatus = {
		success: false,
		error: false,
	}

	const [submitStatus, setSubmitStatus] = useState(initialSubmitStatus)

	const [validationErrors, setValidationErrors] = useState({})

	const form = useRef(null)

	function handleInputChange(e) {
		const formData = {}
		formData[e.target.name] = e.target.value
		setFormState(prevState => ({ ...prevState, ...formData }))
	}

	/* eslint-disable camelcase, react/no-access-state-in-setstate */
	async function submitForm(e) {
		e.preventDefault()
		try {
			setFormState(prevState => ({
				...prevState,
				user_email: formState.senders_email,
			}))

			// Send JSON to worker as text (cf workers are currently unreliable otherwise)
			const res = await fetch(env.CONTACT_WORKER_URL, {
				method: "POST",
				body: JSON.stringify({ ...formState, user_email: formState.senders_email }),
				headers: {
					"content-type": "text/plain", // don't remove
				},
			})

			const data = JSON.parse(await res.text())
			if (data?.error) {
				console.error(data.error)
				setSubmitStatus(prevState => ({ ...prevState, error: true }))
				formSubmitCleanup()
				return
			}

			if (data?.validationErrors) {
				// TODO: Create field specific error UI
				// console.log(data.validationErrors)
				setValidationErrors(data.validationErrors)
				// console.log(validationErrors)
			}

			setSubmitStatus(prevState => ({ ...prevState, success: true }))
			formSubmitCleanup()
		} catch (err) {
			console.error(err)
			setSubmitStatus(prevState => ({ ...prevState, error: true }))
			formSubmitCleanup()
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (submitStatus?.success) {
				setSubmitStatus(prevState => ({ ...prevState, success: false }))
			}
		}, 3000)
	}, [submitStatus?.success])

	function formSubmitCleanup() {
		setFormState(initialState)
	}

	return (
		<FormOuterContainer modifiers={["section", "full_width", "nested", "no_top_pad"]}>
			<FormContainer as="div" modifiers={["block"]}>
				<form id="contact-form" onSubmit={submitForm} ref={form}>
					<label htmlFor="senders_name">
						<span>Name</span>
						<input
							id="senders_name"
							aria-label="name"
							className={validationErrors?.senders_name ? "box-shadow error" : "box-shadow"}
							type="text"
							name="senders_name"
							required
							onChange={e => handleInputChange(e)}
							value={formState.senders_name}
						/>
					</label>

					<label htmlFor="senders_email">
						<span>Email</span>
						<input
							id="senders_email"
							aria-label="email"
							className={validationErrors?.senders_email ? "box-shadow error" : "box-shadow"}
							type="email"
							name="senders_email"
							required
							onChange={e => handleInputChange(e)}
							value={formState.senders_email}
						/>
					</label>

					<label htmlFor="senders_phone">
						<span>Phone</span>
						<input
							id="senders_phone"
							aria-label="phone"
							className={validationErrors?.senders_phone ? "box-shadow error" : "box-shadow"}
							type="text"
							name="senders_phone"
							onChange={e => handleInputChange(e)}
							value={formState.senders_phone}
						/>
					</label>

					<label htmlFor="message_html">
						<span>Message</span>
						<textarea
							id="message_html"
							aria-label="message"
							className={validationErrors?.message_html ? "box-shadow error" : "box-shadow"}
							rows="5"
							name="message_html"
							required
							onChange={e => handleInputChange(e)}
							value={formState.message_html}
						/>
					</label>

					<Button aria-label="send form" type="submit">
						SEND
					</Button>
				</form>
			</FormContainer>
			<SuccessModal
				on={submitStatus.success}
				toggle={() => setSubmitStatus(prevState => ({ ...prevState, success: !submitStatus.success }))}
				email={submitStatus.user_email}
			/>
			<FailedMsg
				on={submitStatus.error}
				toggle={() => setSubmitStatus(prevState => ({ ...prevState, error: !submitStatus.error }))}
			/>
		</FormOuterContainer>
	)
}

const FormOuterContainer = styled(Container)`
	${horizontalWhiteGreyAfterBG};
`

const FormContainer = styled(Container)`
	max-width: 550px;
	position: relative;

	@media only screen and (min-width: 800px) {
		max-width: 550px;
	}

	@media only screen and (min-width: 1200px) {
		max-width: 550px;
	}

	label {
		color: ${darkSlate};
		margin-top: 12px;
		display: inline-block;
		width: 100%;
	}

	span {
		display: inline-block;
		padding-top: 5px;
		font-weight: 700;
		text-transform: uppercase;
		cursor: pointer;

		&:last-child {
			text-transform: none;
			letter-spacing: 0;
			font-weight: 500;
		}
	}

	input,
	textarea {
		font-size: 18px;
		color: black;
	}

	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		margin-top: 2px;
		padding: 8px 12px;
		border: solid 1px ${fadedSlate};
		border-radius: 4px;
		${elevation[2]};
	}

	label:nth-of-type(3) {
		position: absolute;
		top: 48px;
		left: 84px;
		display: block;
		z-index: -1;

		& * {
			display: block;
			height: 1px;
			width: 1px;
		}
	}

	input.error,
	textarea.error {
		box-shadow: 0 0 0 3px red;
	}

	button {
		display: block;
		margin-top: 24px;
		width: 100%;
	}
`
