import React from "react"
import styled from "styled-components"
import { Modal } from "../../lib/Modal.js"
import { Toggle } from "../../lib/Toggle.js"
import { Icon } from "../../lib/Icon.js"
import { theme } from "../../styles/colors.js"
import { SubHeading } from "../../styles/headings.js"

export default function SuccessModal({ on, toggle, email }) {
	return (
		<Toggle>
			{() => (
				<Modal on={on} toggle={toggle}>
					<Icon name="verify" width={165} height={165} />
					<SubHeading css="margin-top: 28px" color={theme.black}>
						Thank you for reaching out!
					</SubHeading>
					{email && (
						<Sent css="margin: 16px 0 12px;">
							<span>We sent an email to:</span>
							&nbsp;
							<span>{email}</span>
						</Sent>
					)}
					<p>We will get back to you as quickly as possible.</p>
				</Modal>
			)}
		</Toggle>
	)
}

const Sent = styled.p`
	color: ${theme.black};

	@media only screen and (min-width: 700px) {
		margin: 4px;
		line-height: 1;
	}

	span {
		color: ${theme.black};
	}

	span:nth-of-type(1) {
		@media only screen and (max-width: 700px) {
			display: block;
		}
		font-size: 0.98rem;
	}

	span:nth-of-type(2) {
		font-size: 0.98rem;
		font-weight: 700;
	}
`
