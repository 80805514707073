import React from "react"
import styled from "styled-components"
import { BrandLogo } from "../../assets/logos"
import { Hero } from "../../lib/Hero"
import { fadedSlate, Container, Para } from "../../styles"

export default function ContactHero() {
	return (
		<HeroWrapper>
			<CopyContainer as="div">
				<h1 className="page-heading page-heading-left">
					How can I help
					<br />
					you today?
				</h1>
				<Para modifiers={["xtraNarrow", "left"]}>
					Reach out! No mailing lists or spam. Just a quick follow up to find out how I can help you.
				</Para>
			</CopyContainer>
			<LogoWrapper>
				<BrandLogo name="logo" width={200} height={200} fill={fadedSlate} hover={fadedSlate} />
			</LogoWrapper>
		</HeroWrapper>
	)
}

const HeroWrapper = styled(Hero)`
	position: relative;
	justify-items: center;
	align-items: center;
	padding-bottom: 60px;
`

const CopyContainer = styled(Container)`
	z-index: 1;

	h1 {
		padding-top: 50px;
	}
`

const LogoWrapper = styled.div`
	position: absolute;
	transform: translate(-50%, -50%);
	top: 102%;
	left: 75%;

	@media only screen and (min-width: 500px) {
		top: 75%;
		left: 75%;
	}

	svg {
		width: 250px;
		height: 250px;
		filter: opacity(25%);
		transform: scale(1.5);
		z-index: -5;

		@media only screen and (min-width: 500px) {
			transform: scale(2);
		}

		@media only screen and (min-width: 800px) {
			transform: scale(3);
		}

		@media only screen and (min-width: 960px) {
			transform: scale(3.75);
		}
	}
`
