import React from "react"
import styled from "styled-components"
import Layout_main from "../layouts/Layout_main.js"
import ContactForm from "../components/contact/ContactForm.js"
import ContactHero from "../components/contact/ContactHero.js"

export default function ContactPage({ location }) {
	return (
		<Layout_main location={location}>
			<Page>
				<ContactHero />
				<ContactForm />
			</Page>
		</Layout_main>
	)
}

const Page = styled.div`
	overflow: hidden;
`
